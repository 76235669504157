a {
  text-decoration: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.layout {
  padding-left: 55px;
  padding-right: 55px;
}

.profile_avatar {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  padding: 0;
  border: .12em solid #3a3b3b;
  margin: 0 .3em 0 0;
  vertical-align: middle;
  width: 3.3em;
  height: 4.2em;
}

.avatar {
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  padding: 0;
  border: .120em solid #eaeded;
  margin: 0 .3em 0 0;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
}

.avatar img {
  max-width: 2em;
  max-height: 2em;
}

.logo {
  display: inline-block;
  overflow: hidden;
  text-align: center;
  padding: 0;
  margin: 0 .3em 0 0;
  vertical-align: middle;
  width: 2em;
  height: 2em;
}

.logo img {
  max-width: 3em;
  max-height: 3em;
}


.formfield {
  height: 35px;
  margin:0;
}

.mandatory {
  display: inline-block;
  color: red;
}

.committed {
  background-color: #336CAF;
  display: inline-block;
  color: white;
  width: 35px;
  text-align: center;
}

.pipelined {
  background-color: #EA7228;
  display: inline-block;
  color: black;
  width: 50px;
  text-align: center;
}

.unassigned {
  background-color: black;
  display: inline-block;
  opacity: 0.7;
  color: white;
  width: 50px;
  text-align: center;
}

.overloaded {
  background: repeating-linear-gradient(45deg, #FFC315, #FFC315 10px, #eee 10px, #eee 20px);
  display: inline-block;
  width: 80px;
  text-align: center;
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.w3-light-grey, .w3-hover-light-grey:hover{
  color: #000!important;
  background-color: #f1f1f1!important;
  border-radius: 5rem;
}
.w3-blue, .w3-hover-blue:hover {
  color: #fff!important;
  background-color: #7d8893!important;
  border-radius: 5rem;
}

.w3-text-white, .w3-hover-text-white:hover {
  color: #fff!important;
}

.w3-center {
  text-align: center!important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
